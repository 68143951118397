$input_color: #FF5548;
$input_cta_color: lighten($input_color, 15%);
$red: #FF0000;
$black: #000000;
$white: #FFFFFF;
$green: #92BE7B;
$overtime: #F76363;
$ease-out: all .3s cubic-bezier(0.23, 1, 0.32, 1);

/*Inputs styles*/
input:not([type]):focus:not([readonly]),
input[type=text]:focus:not([readonly]),
input[type=password]:focus:not([readonly]),
input[type=email]:focus:not([readonly]),
input[type=url]:focus:not([readonly]),
input[type=time]:focus:not([readonly]),
input[type=date]:focus:not([readonly]),
input[type=datetime]:focus:not([readonly]),
input[type=datetime-local]:focus:not([readonly]),
input[type=tel]:focus:not([readonly]),
input[type=number]:focus:not([readonly]),
input[type=search]:focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]){
  border-bottom: 1px solid $input_color;
  box-shadow: 0 1px 0 0 $input_color;
}
input:not([type]):focus:not([readonly]), input[type=text]:not(.browser-default):focus:not([readonly]), input[type=password]:not(.browser-default):focus:not([readonly]), input[type=email]:not(.browser-default):focus:not([readonly]), input[type=url]:not(.browser-default):focus:not([readonly]), input[type=time]:not(.browser-default):focus:not([readonly]), input[type=date]:not(.browser-default):focus:not([readonly]), input[type=datetime]:not(.browser-default):focus:not([readonly]), input[type=datetime-local]:not(.browser-default):focus:not([readonly]), input[type=tel]:not(.browser-default):focus:not([readonly]), input[type=number]:not(.browser-default):focus:not([readonly]), input[type=search]:not(.browser-default):focus:not([readonly]), textarea.materialize-textarea:focus:not([readonly]) {
  border-bottom: 1px solid $input_color;
  -webkit-box-shadow: 0 1px 0 0 $input_color;
  box-shadow: 0 1px 0 0 $input_color;
}
input:not([type]):focus:not([readonly])+label, input[type=text]:not(.browser-default):focus:not([readonly])+label, input[type=password]:not(.browser-default):focus:not([readonly])+label, input[type=email]:not(.browser-default):focus:not([readonly])+label, input[type=url]:not(.browser-default):focus:not([readonly])+label, input[type=time]:not(.browser-default):focus:not([readonly])+label, input[type=date]:not(.browser-default):focus:not([readonly])+label, input[type=datetime]:not(.browser-default):focus:not([readonly])+label, input[type=datetime-local]:not(.browser-default):focus:not([readonly])+label, input[type=tel]:not(.browser-default):focus:not([readonly])+label, input[type=number]:not(.browser-default):focus:not([readonly])+label, input[type=search]:not(.browser-default):focus:not([readonly])+label, textarea.materialize-textarea:focus:not([readonly])+label {
  color: $input_color;
}
input[readonly]:not(.select-dropdown) {
  color: rgba(0,0,0,0.42);
  border-bottom: 1px dotted rgba(0,0,0,0.42);
}
input[type=text][readonly],
textarea[readonly] {
  color: inherit !important;
  border-bottom: 1px solid #9e9e9e !important;
}
input[type=checkbox][disabled] + span:before {
  background-color: inherit !important;
  border: 2px solid #5a5a5a !important;
}
input[type=checkbox][disabled]:checked + span:not(.lever):before {
  border-right: 2px solid $input_color !important;
  border-bottom: 2px solid $input_color !important;
  border-left: none !important;
  border-top: none !important;
}
label{
  color: $input_color;
}
label.required{
  &:after{
    content: ' *';
    color: red;
    margin-left: 2px;
  }
}
[type="radio"]+label{
  margin-right: 5px;
  &:after{
    content: '';
  }
}

.btn{
  background-color: $input_color;
  &:hover{
    background-color: $input_cta_color;
  }
  &:focus{
    background-color: $input_color;
  }
  &.alert{
    background-color: #ff8a80;
    &:hover{
      background-color: #ff5252;
    }
    &:hover{
      background-color: #ff8a80;
    }
  }
}
button.btn i{
  vertical-align: text-bottom;
  line-height: initial;
}

#image-manager{
  #image-manager-header{
    margin-bottom: 10px;
  }
  .image-manager-content{
    min-height: 300px;
    p{
      width: 100%;
    }
    #image-manager-form{
      width: 100%;
      border-style: dashed;
      border-radius: 5px;
    }
  }
  #edit_image{
    .image-thumbnail-container{
      margin: 10px;
      display: inline-block;
      .image-thumbnail{
        width: 150px;
        height: 150px;
        border: 5px solid #ffffff;
        cursor: pointer;
        &.selected{
          border-color: $input_color;
        }
      }
    }
  }
}
.select-wrapper{
  .dropdown-content{
    li{
      span{
        color: $input_color;
      }
      &.selected{
        background-color: $input_color;
        span{
          color: #ffffff;
        }
      }
    }
  }
}

.tabs{
  .tab{
    a{
      color: lighten($input_color, 15%);
      &.active, &:hover{
        color: $input_color;
      }
    }
  }
  .indicator{
    background-color: $input_color;
  }
}

[type="radio"]:checked+label:after,
[type="radio"].with-gap:checked+label:before,
[type="radio"].with-gap:checked+label:after {
  border: 2px solid $input_color;
}

[type="radio"]:checked+label:after,
[type="radio"].with-gap:checked+label:after {
  background-color: $input_color;
  z-index: 0;
}