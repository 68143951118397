@import "partials/input";
// Custom MQ
@mixin mq($min, $max) {
    @if $min == null {
        @media (max-width: #{$max}) {
            @content;
        }
    } @else if $max == null {
        @media (min-width: #{$min}) {
            @content;
        }
    } @else {
        @media (min-width: #{$min}) and (max-width: #{$max}) {
            @content;
        }
    }
}
.fancybox-skin {
    background-color: #eeeded
}

body {
    font-family: "Roboto", sans-serif;
    background-color: #eeeded;
    a {
        color: $input_color;
    }
    @include mq(null,992px){
        padding-top: 50px;
        &.menu-open {
            overflow: hidden;
            &:after {
                content: "";
                position: fixed;
                top: 50px;
                left: 0;
                width: 100%;
                bottom: 0;
                background-color: rgba(0,0,0,.8);
                z-index: 1000;
            }
        }
    }
}
nav {
        &.top-nav {
            background-color: $input_color;
            height: 50px;
            line-height: 50px;
            font-size: 14px;
            position: relative;
            z-index: 999;
            @include mq(null,992px){
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                z-index: 1200;
            }
            .brand-logo {
                display: block;
                float: left;
                text-transform: uppercase;
                font-size: 14px;
                width: 200px;
                padding: 0 20px;
            }
            li {
                &.dropdown-container {
                    ul {
                        li {
                            a {
                                color: #333;
                                font-size: 13px;
                            }
                        }
                    }
                }
            }
            a {
                color: #fafafa;
                font-weight: normal;
                i {
                    height: inherit;
                    line-height: inherit;
                    font-size: 14px;
                }
                &:hover {
                    color: #ffffff;
                    background-color: inherit;
                }
            }
            .sidenav-trigger{
                display: block;
                height: 50px;
                line-height: 50px;
                margin: 0;
                @include mq(null,992px){
                    i.open {
                        display: none;
                        .menu-open & {
                            display: block;
                        }
                    }
                    i.close {
                        display: block;
                        .menu-open & {
                            display: none;
                        }
                    }
                }
            }
        }
    }

.sidenav {
    li {
        > a {
            display: flex;
            align-items: center;
            min-height: 48px;
            line-height: 1.2;
        }
    }
}

.page-content {
    float: left;
    width: 100%;
    @include mq(null,992px){
        .menu-open & {
            overflow: hidden;
        }
    }
}

#slide-out {
    @include mq(null,992px){
        margin: 0;
        &.pinned,
        &.pin-top {
            position: relative !important;
            height: auto;
        }
    }
}

.sidebar-main {
    float: left;
    width: 200px;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    @include mq(null,992px){
        float: none;
        position: fixed;
        top: 50px;
        left: 0;
        bottom: 0;
        overflow: auto;
        transform: translateX(-100%);
        z-index: 1100;
        background-color: #FFF;
        .menu-open & {
            transform: translateX(0);
        }
    }
    a {
        font-weight: normal;
    }
    .side-nav {
        position: relative;
        width: 200px;
        height: 100vh;
        z-index: 997;
        margin-top: 0;
        background-color: white;
        .userView {
            position: relative;
            overflow: hidden;
            padding: 32px 32px 0;
            .background{
                position: absolute;
                z-index: -1;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
            }
            .name {
                margin: 16px 0;
                font-weight: 500;
            }
            .sidebar-user-material-menu {
                padding: 0 30px;
                margin: 20px -32px 0;
                background-color: rgba(0, 0, 0, .15);
                a {
                    color: #fff;
                    text-shadow: 0 0 1px rgba(0, 0, 0, .5);
                    //height: 20px;
                    line-height: 20px;
                    padding: 10px 0;
                    width: 100%;
                    i {
                        float: right;
                    }
                }
            }
        }
        #main-menu {
            margin: 0;
            box-shadow: none;
            border: none;
            & > li {
                & > a {
                    padding: 0 16px;
                    height: 48px;
                    line-height: 48px;
                    display: flex;
                    border-bottom: none;
                    font-weight: 500;
                    color: lighten(black, 13%);
                    &:hover{
                        background-color: lighten(black, 97%);
                    }
                    .material-icons{
                        color: lighten(black, 46%);
                        height: 48px;
                        line-height: 48px;
                        margin-right: 1rem;
                        width: 30px;
                        text-align: center;
                    }
                    &.collapsible-header {
                        position: relative;
                        &:after {
                            content: "\e315";
                            font-family: 'Material Icons';
                            font-size: 16px;
                            display: block;
                            position: absolute;
                            top: 50%;
                            margin-top: -8px;
                            right: 20px;
                            line-height: 1;
                            -webkit-transform: rotate(0);
                            -ms-transform: rotate(0);
                            -o-transform: rotate(0);
                            transform: rotate(0);
                            -webkit-transition: -webkit-transform .2s ease-in-out;
                            -o-transition: -webkit-transform .2s ease-in-out;
                            transition: -webkit-transform .2s ease-in-out;
                        }
                    }
                    span.badge {
                        position: absolute;
                        //line-height: 0;
                        min-width: inherit;
                        border-radius: 10px;
                        width: 20px;
                        height: 20px;
                        display: block;
                        top: 30%;
                        right: 40px;
                    }
                }
                &.active {
                    & > a {
                        &.collapsible-header {
                            &:after {
                                -webkit-transform: rotate(90deg);
                                -ms-transform: rotate(90deg);
                                -o-transform: rotate(90deg);
                                transform: rotate(90deg);
                            }
                        }
                    }
                }
                .collapsible-body {
                    padding: 0;
                    ul {
                        li {
                            height: 40px;
                            &.active {
                                background-color: $input_color;
                                a{
                                    color: white;
                                    &:hover{
                                        background-color: initial;
                                    }
                                }
                            }
                            a{
                                display: flex;
                                padding: 0 23.5px 0 31px;
                                color: lighten(black, 13%);
                                &:hover{
                                    background-color: lighten(black, 97%);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.sidebar-fav {
    #slide-out-fav {
        width: 400px;
        max-width: calc(100% - 50px);
        @include mq(null,992px){
            top: 50px;
            bottom: 0;
        }
        li {
            #fav-close {
                padding-right: 25px;
                padding-top: 10px;
                i {
                    cursor: pointer;
                }
            }
            .divider {
                margin: 0;
            }
            &.fav-header {
                background-color: $input_color;
                a, i {
                    color: #ffffff;
                }
            }
            ul {
                li {
                    a, i {
                        font-weight: normal;
                        line-height: 17px;
                        height: auto;
                        padding-top: 5px;
                        padding-bottom: 5px;
                        min-height: 35px;
                        display: flex;
                        align-items: center;
                    }
                    i.material-icons {
                        margin-right:12px;
                    }
                }
            }
        }
    }
}

.minified-sb {
    .sidebar-main {
        width: 56px;
        overflow: hidden;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        transition: all 0.5s ease;
    }
    .content-wrapper {
        padding-left: 56px;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        transition: all 0.5s ease;
    }
}

.content-wrapper {
    padding-left: 200px;
    //min-height: 100vh;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    @include mq(null,992px){
        padding-left: 0;
    }
    .page-header {
        margin-bottom: 30px;
        -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24);
        box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24);
        .page-header-content {
            padding: 0 20px;
            .page-title {
                padding: 0;
                height:100px;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: space-between;
                h4 {
                    display: inline-block;
                    margin: 0;
                    letter-spacing: -.015em;
                    font-size: 19px;
                    font-family: inherit;
                    font-weight: 400;
                    line-height: 1.5384616;
                    color: inherit;
                    span {
                        font-weight: 500;
                    }
                }
                .heading-elements {
                    display: inline-block;
                    float: right;
                    .company-select {
                        float: right;
                    }
                    a {
                        display: block;
                        text-align: center;
                        float: right;
                        margin: 0 10px;
                        i {
                            color: $input_color;
                        }
                        span {
                            color: #333;
                            font-size: 13px;
                        }
                        &:hover {
                            i, span {
                                color: $input_cta_color;
                            }
                        }
                    }
                    input {
                        margin-bottom:0;
                    }
                    .dropdown-content {
                        li {
                            &.selected {
                                background-color: $input_color;
                            }
                        }
                    }
                }
                @include mq(null,992px){
                    flex-direction: column;
                    height: auto;
                    h4,
                    .heading-elements {
                        width: 100%;
                        padding: 5px 0;
                    }
                    //.heading-elements {
                    //    > a {
                    //        float: left;
                    //    }
                    //}
                }
            }
        }
        .breadcrumb-line {
            nav {
                background-color: #fcfcfc;
                border-top: 1px solid #ddd;
                box-shadow: none;
                height: 40px;
                line-height: 40px;
                .nav-wrapper {
                    a {
                        color: #333;
                        font-size: 13px;
                        i {
                            display: inline-block;
                            font-size: 16px;
                            margin-right: 8px;
                            height: 40px;
                            line-height: 40px;
                        }
                        &.breadcrumb {
                            &:before {
                                content: '/';
                                color: #333;
                                font-size: 13px;
                            }
                        }
                        &:last-child {
                            color: #999;
                        }
                    }
                }
            }
        }
    }
}

.flashbag-messages {
    margin-right: 33.3333333333%;
}

.card {
    font-size: 13px;
    .datatable-table,
    .table-overflow {
        @include mq(null, 992px) {
            overflow: auto;
            width: 100%;
        }
    }
    .table-overflow-last-times,
    .table-overflow-estimate-history-send,
    .table-overflow-user-task-time {
        table {
            @include mq(null, 992px) {
                width: clamp(1px, 175%, 100%);
            }
        }
    }
    .card-title {
        font-size: 17px;
    }
    @include mq(null, 992px) {
        .card-content-charts {
            padding: 0;
        }
    }
    #estimate-chart,
    #estimate-chart-total,
    #estimate-chart-regie,
    #global-chart,
    #rentability-chart,
    #rentability-money-chart {
        min-width: 310px;
        max-width: 100%;
        height: 400px;
        margin: 0 auto;
        @include mq(null, 992px) {
            width: 100%;
            min-width: initial;
        }
    }
    #global-chart {
        height: 650px;
    }
    #rentability-chart,
    #rentability-money-chart {
        height: 600px;
    }
    .card-action {
        .row {
            margin-bottom: 0;
        }
    }
    .datepicker{
        min-width: 85px;
    }
    .card-favorite {
        position: absolute;
        top: 24px;
        right: 24px;
        cursor: pointer;
    }
    .card-content {
        .btn {
            &.mt-10 {
                @include mq(null, 992px) {
                    margin-top: 5px;
                }
            }
        }
    }
}

.waitable {
    .preloader {
        display: none;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;
        background-color: rgba(255, 255, 255, 0.87);
    }
    &.waiting {
        .preloader {
            display: block
        }
    }
}

.chart-container {
    position: relative;
    padding: 0;
    .chart-nav {
        position: absolute;
        top: 0;
        bottom: 0;
        color: #777777;
        z-index: 999;
        i {
            cursor: pointer;
        }
        &.disabled {
            color: #E0E0E3;
            cursor: default;
        }
        &.right {
            right: 10px;
        }
        &.left {
            left: 10px;
        }
    }
}

.card {
    table {
        &.datagrid {

        }
    }
    .collapsible.no-margin {
        //margin: 0 -20px;
        box-shadow: none;
        border-right: none;
        border-left: none;
        .dataTables_wrapper {
            margin: 0;
        }
        .contact-card {
            i {
                vertical-align: text-bottom;
            }
        }
    }
    .dataTables_wrapper {
        //margin: 0 -20px;
        border-top: 1px solid #ddd;
        .datatable-header {
            float: left;
            width: 100%;
            border-bottom: 1px solid #ddd;
            .dataTables_length {
                float: right;
                margin-right: 20px;
                label {
                    display: inline-block;
                    & > span {
                        float: left;
                        line-height: 38px;
                        margin-right: 10px;
                        color: #333;
                    }
                    .select-wrapper {
                        width: 75px;
                        float: left;
                        color: #333;
                        span {
                            top: 14px;
                            height: auto;
                        }
                        input.select-dropdown {
                            height: 38px;
                            font-size: 13px;
                        }
                        .dropdown-content {
                            top: 39px !important;
                            li {
                                &.selected {
                                    background-color: $input_color;
                                }
                            }
                        }
                    }
                }
            }
        }
        .datatable-table {
            table {
                border-spacing: 0;
                border-collapse: collapse;
                tr {
                    padding: 0 20px;
                    td, th {
                        font-size: 13px;
                    }
                    td {
                        padding-left: 10px;
                        padding-right: 10px;
                        span.badge {
                            position: relative;
                            font-weight: bold;
                            cursor: pointer;
                            &.taxonomy {
                                margin: 5px 0;
                                &.accept:after {
                                    content: '';
                                }
                            }
                        }
                        .send_email_action {
                            color: $input_color;
                            cursor: pointer;
                        }
                        a {
                            color: $input_color;
                        }
                        .archive_select {
                            width: 16px;
                            height: 16px;
                            border: 1px solid $input_color;
                            cursor: default;
                            input {
                                border: 0;
                                clip: rect(0 0 0 0);
                                height: 1px;
                                margin: -1px;
                                overflow: hidden;
                                padding: 0;
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 1px;
                                pointer-events: initial;
                            }
                            input:checked + i {
                                opacity: 1;
                            }
                            i {
                                font-size: 14px;
                                color: $input_color;
                                opacity: 0;
                                transition: opacity 0.3s;
                            }
                        }
                        .billing-send i {
                            font-size: 14px;
                        }
                    }
                }
                thead {
                    tr {
                        th {
                            border-bottom: 1px solid #bbb;
                            input {
                                box-sizing: border-box;
                                border: 1px solid #9e9e9e;
                                border-radius: 5px;
                                height: 30px;
                                margin: 0;
                                padding: 0 10px;
                                background-color: #ffffff;
                                font-size: 13px;
                                font-weight: normal;
                                &:focus,
                                &.filtered {
                                    border: 1px solid $input_color;
                                    box-shadow: 0 1px 0 0 #26a69a;
                                }
                            }
                            .actions {
                                display: flex;
                                justify-content: flex-end;
                                align-items: flex-start;
                            }
                            .reset-filters {
                                color: $input_color;
                                cursor: pointer;
                            }
                            .btn-archive,
                            .btn-email {
                                background-color: transparent;
                                border: none;
                                color: $input_color;
                                appearance: none;
                                padding: 0;
                                //margin-left: 5px;
                            }
                            .archive_all {
                                width: 16px;
                                height: 16px;
                                border: 1px solid $input_color;
                                cursor: default;
                                input {
                                    border: 0;
                                    clip: rect(0 0 0 0);
                                    height: 1px;
                                    margin: -1px;
                                    overflow: hidden;
                                    padding: 0;
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: 1px;
                                    pointer-events: initial;
                                }
                                input:checked + i {
                                    opacity: 1;
                                }
                                i {
                                    font-size: 14px;
                                    color: $input_color;
                                    opacity: 0;
                                    transition: opacity 0.3s;
                                }
                            }
                            .btn-email {
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
        .datatable-footer {
            float: left;
            width: 100%;
            border-top: 1px solid #ddd;
            padding: 20px;
            @include mq(null, 992px) {
                padding: 20px 0;
            }
            .dataTables_paginate {
                a {
                    color: #333;
                    &.disabled {
                        color: #bbb;
                    }
                }
                span {
                    a {
                        font-size: 1.2rem;
                        padding: 0 10px;
                        line-height: 30px;
                        border-radius: 5px;
                        &.current {
                            background-color: $input_color;
                            color: white;
                        }
                    }
                }
            }
        }
    }
}

.select2-group-field {
    .autocomplete_add_block {
        .autocomplete_add {
            margin-top: 20px;
        }
    }
}

body {
    &.project {
        .notettext {
            p {
                padding: inherit;
            }
        }
        ul.collapsible{

        }
    }
}

#recap-compta {
    table {
        tbody {
            tr {
                td.choosable {
                    cursor: pointer;
                }
            }
        }
    }
    #popup-days {
        display: none;
    }
    #actual_month{
        //width: initial;
    }
}

#pdf-estimate {
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    background-color: #ffffff;
    border-top: 1px solid #e0e0e0;
    z-index: 5;
    .row {
        margin: 10px 0;
        a {
            i {
                vertical-align: bottom;
                margin-right: 5px;
            }
        }
    }
}

#pdf-estimate-time {
    background-color: #ffffff;
    border-top: 1px solid #e0e0e0;
    .row {
        margin: 10px 0;
        a {
            i {
                vertical-align: bottom;
                margin-right: 5px;
            }
        }
    }
}

form[name="prevision_list"], form[name="company_list"], form[name="billing_list"] {
    //margin-top: -10px;
    input[type="text"] {
        height: inherit;
    }
}
#prevision-list_wrapper {
    margin: -20px;
    border-top: none;
}
#prevision-list {
    .even {
        background-color: #eeeeee;
    }
    td {
        vertical-align: middle;
    }
    .prevision-invoice {
        color: green;
    }
    .prevision-balance {
        color: red;
    }
    .fancy_view2:not([data-total="0.00"]), .fancy_view3:not([data-total="0.00"]) {
        cursor: pointer;
    }
}
[type="radio"]:checked + span:after, [type="radio"].with-gap:checked + span:before, [type="radio"].with-gap:checked + span:after {
    border: 2px solid $input_color;
    background-color: $input_color;
}

[type="checkbox"]:checked+span:not(.lever):before {
    border-right: 2px solid $input_color;
    border-bottom: 2px solid $input_color;
}
form[name="billing_list"] .s2 .select-wrapper{
    min-width: 135px;
}
.input-field.field-autocomplete{
    margin: 0;
}

#nav-month {
    a {
        padding: 0;
    }
}
#stat_filter {

}
.fancybox-opened {
    @include mq(null,992px){
        z-index: 10000;
    }
}

.breadcrumb-line {
    @include mq(null,992px){
        white-space: nowrap;
        overflow: auto;
    }
}

span {
    &.red-flag {
        i {
            color: $red;
        }
    }
    &.month-flag {
        i {
            color: $input_color;
        }
    }
    &.annual-flag {
        i {
            color: #26a69a;
        }
    }
}

.dataTables_wrapper {
    .dataTables_processing {
        .estimate-list & {
            position: fixed;
            background: none;
        }
    }
}

:root {
    --time-spent-percentage: 0%;
    --white: #{$white};
    --black: #{$black};
    --draglist-height: 0;
    --blue-dark: #2F3D44;
    --grey-lighten: #FBFBFB;
    --overtime: #{$overtime};
    --zone-color: transparent;
    --card-percentage: 0;
    --green: #{$green};
    --grey-light: #E7E7E7;
    --add-button: #1D201C;
}

.div-times {
    display: flex;
    justify-content: left;
    align-items: center;
    i {
        color: $input_color;
        margin-left: 15px;
    }
}

.time-spent-percentage {
    text-align: center;
    width: 200px;
    height: 20px;
    border-radius: 5px;
    border: 1px solid $input_color;
    position: relative;
    &:before {
        content: '';
        background-color: $input_color;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: var(--time-spent-percentage);
        max-width: 100%;
    }
    span {
        position: relative;
    }
}
#recap-legend {
    .recap-item {
        padding: 15px 5px;
        border-radius: 2px;
        @include mq(null, 992px) {
            margin: 0.75rem 0;
        }
    }
}
#wiki_filter,
#pdf-estimate {
    .btn {
        &.mt-10 {
            margin-top: 5px;
        }
    }
}
.archive-list {
    .datagrid {
        .first-column {
            width: 50%;
        }
        .other-column {
            width: 20%;
        }
        .last-column {
            width: 5%;
        }
    }
}

//form {
//    &#stat_filter,
//    #stat_filter-regie {
//        height: 400px;
//        margin: 0;
//        @include mq(null, 992px) {
//            height: auto;
//        }
//    }
//}

.drag {
    &-container {}
    &-list {
        .drag-item {
            margin: 15px;
        }
    }
    &-column {
        //max-width: 320px;
        background: none !important;
        .drag-column-header {
            display: block;
            background-color: var(--white);
            padding: 10px 20px;
            border-radius: 10px;
            margin-bottom: 15px;
            border: none;
            h2 {
                font-size: 16px;
                font-weight: 600;
                color: var(--blue-dark);
                text-align: left;
                text-transform: none;
                line-height: 1.1;
            }
        }
        .drag-inner-list {
            background-color: var(--white) !important;
            border-radius: 15px;
        }
    }
    &-item {
        border-radius: 15px;
        box-shadow: none;
        list-style: none;
        a.card-item {
            position: relative;
            background-color: #F5F6F9;
            border-radius: 15px;
            padding: 15px;
            overflow: hidden;
            &:hover {
                background-color: #F5F6F9;
            }
            &.nocontract {
                &:before,
                &:after {
                    display: none;
                }
            }
        }
        &:hover,
        &.is-moving {
            .card {
                &--item {
                    background-color: $input_color;
                }
            }
        }
        .card {
            &--item {
                position: relative;
                background-color: var(--grey-light);
                border-radius: 15px;
                padding: 5px 15px;
                overflow: hidden;
                width: 100%;
                transition: all 0.3s;
            }
            &--flag {
                position: absolute;
                top: 0;
                right: 0;
                font-size: 9px;
                font-weight: 700;
                color: var(--white);
                padding: 10px 20px;
                background-color: var(--green);
                border-radius: 0 15px 0 15px;
            }
            &-content {}
            &--elapsed-time {
                margin: 5px 0;
                &--time {
                    font-size: 15px;
                    font-weight: 700;
                    color: var(--black);
                    text-align: center;
                    margin-bottom: 5px;
                }
                &--progress {
                    display: flex;
                    align-items: center;
                    &--bar {
                        position: relative;
                        flex: 0 1 100%;
                        height: 10px;
                        border-radius: 20px;
                        background-color: #CCCCCC;
                        span {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: var(--card-percentage);
                            height: 100%;
                            border-radius: 20px;
                            background-color: var(--zone-color);
                        }
                    }
                    &--pc {
                        flex: 0 0 auto;
                        padding-left: 10px;
                        font-size: 12px;
                        font-weight: 300;
                        text-align: right;
                        color: var(--black);
                    }
                }
                &.overtime {
                    .card--elapsed-time--time,
                    .card--elapsed-time--progress--pc {
                        color: var(--overtime);
                        font-weight: 700;
                    }
                }
            }
            &-name {
                font-size: 14px;
                font-weight: 500;
                color: var(--black);
                margin: 0;
                span {
                    font-size: 13px;
                }
            }
            &--footer {
                margin-top: 10px;
                .date {
                    font-size: 11px;
                    color: var(--blue-dark);
                }
            }
        }
        &.open {
            .tasks-list {
                &-truncate {
                    display: none;
                }
                &-full {
                    display: block;
                }
            }
        }
        .tasks-list {
            padding: 0;
            margin: 0;
            font-size: 12px;
            color: var(--black);
            background-color: transparent;
            margin-top: 5px;
            &-truncate {
                transition: all 0.3s;
                max-height: 55px;
                overflow: hidden;
            }
            &-full {
                display: none;
                transition: all 0.3s;
            }
            &-toggle {
                font-size: 11px;
                color: var(--black);
                margin: 0;
                text-decoration: underline;
            }
            //span {
            //    display: block;
            //    width: 100%;
            //    max-width: 100%;
            //    text-overflow: ellipsis;
            //    white-space: nowrap;
            //    overflow: hidden;
            //}
            ul li {
                margin: 0;
            }
        }
        a.card-item:hover .tasks-list span {
            white-space: normal;
        }
    }
}

.planning {
    @media (min-width: 376px) {
        overflow-x: auto;
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    &--search {
        padding: 0 10px;
        border-radius: 50px;
        background-color: var(--grey-light);
        @include mq(null,992px) {
            margin: 0 0 10px 0;
        }
        input[type="search"] {
            color: var(--add-button);
            border: none;
            margin: 0;
            &::placeholder {
                color: var(--add-button);
            }
            &:focus:not([readonly]) {
                border: none;
                box-shadow: none;
            }
        }
    }
}

.drag-list {
    height: calc(100vh - var(--draglist-height));
}

.drag-column .drag-inner-list {
    overflow-y: auto;
}

.drag {
    &-container {
        margin: 0;
    }

    &-list {
        display: flex;
        height: calc(100dvh - var(--draglist-height));
        overflow-y: hidden;

        .planning & {
            padding-bottom: 20px;
        }

        .drag-item {
            margin: 15px 0;

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &-header {
        &-move {
            cursor: pointer;
        }
    }

    &-column {
        flex: 0 0 16%;
        background: none !important;
        margin: 0 5px;
        display: flex;
        flex-direction: column;
        position: relative;
        background: rgba(black, 0.1);
        @media (max-width: 690px) {
            margin-bottom: 30px;
        }
        @include mq(null, 375px) {
            flex-basis: 80%;
        }

        h2 {
            margin: 0;
        }

        &-done {
            opacity: .6;
        }

        &-header {
            display: block;
            background-color: var(--white);
            padding: 10px 20px;
            border-radius: 10px;
            margin-bottom: 15px;
            border: none;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 5px;
            border-bottom: 1px solid rgba($black, 0.1);
            border-right: 1px transparent;
            font-size: 13px;
            padding-top: 5px;
            position: sticky;
            top: 0;
            z-index: 10;

            h2 {
                font-size: 16px;
                font-weight: 600;
                color: var(--blue-dark);
                text-align: left;
                text-transform: none;
                line-height: 1.1;
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100%;
                margin: 0;
            }
        }

        .drag-inner-list {
            background-color: var(--white);
            border: 15px solid var(--white);
            border-right-width: 5px;
            border-radius: 15px;
            overflow-y: auto;
            padding-right: 10px;
        }
    }

    &-inner {
        &--list {
            min-height: 50px;
            flex: 1;
        }
    }

    &-item {
        border-radius: 15px;
        box-shadow: none;
        list-style: none;

        .card-item {
            .planning & {
                cursor: pointer;
            }
        }

        a {
            display: block;
            color: inherit;
            position: relative;
            z-index: 1;
            transition: .2s all;

            &.card-item {
                &:hover {
                    cursor: pointer;
                }
            }

            &.merge {
                background: rgba(255, 255, 255, .5);
                font-size: 0;
                text-align: center;

                &:hover {
                    background: rgba(255, 255, 255, .8);
                }

                svg {
                    transform: rotate(-90deg);
                }
            }
        }

        &.active {
            a:after {
                position: absolute;
                z-index: 0;
                content: "";
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(grey, .3);
                animation: blinker 1s linear infinite;
            }
        }

        &.is-moving {
            background: white;

            a {
                cursor: move; /* fallback if grab cursor is unsupported */
                cursor: grabbing;
            }
        }
    }
    &-options {
        position: absolute;
        top: 44px;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 10px;
        transform: translateX(100%);
        opacity: 0;
        transition: $ease-out;

        &.active {
            transform: translateX(0);
            opacity: 1;
        }

        &-label {
            display: block;
            margin: 0 0 5px 0;

            input {
                opacity: 0.6;
            }

            span {
                display: inline-block;
                font-size: 0.9rem;
                font-weight: 400;
                margin-left: 5px;
            }
        }
    }
}

.card-item {
    .color-stripe {
        min-height: 4px;
        position: relative;
        z-index: 9;
        margin-bottom: -1px;
    }
}

/* Dragula CSS  */
.gu-mirror {
    position: fixed !important;
    margin: 0 !important;
    z-index: 9999 !important;
}

.gu-hide {
    display: none !important;
}

.gu-unselectable {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
}

.gu-transit {
    opacity: 0.4;
}