/*Inputs styles*/
input:not([type]):focus:not([readonly]),
input[type=text]:focus:not([readonly]),
input[type=password]:focus:not([readonly]),
input[type=email]:focus:not([readonly]),
input[type=url]:focus:not([readonly]),
input[type=time]:focus:not([readonly]),
input[type=date]:focus:not([readonly]),
input[type=datetime]:focus:not([readonly]),
input[type=datetime-local]:focus:not([readonly]),
input[type=tel]:focus:not([readonly]),
input[type=number]:focus:not([readonly]),
input[type=search]:focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]) {
  border-bottom: 1px solid #FF5548;
  box-shadow: 0 1px 0 0 #FF5548; }

input:not([type]):focus:not([readonly]), input[type=text]:not(.browser-default):focus:not([readonly]), input[type=password]:not(.browser-default):focus:not([readonly]), input[type=email]:not(.browser-default):focus:not([readonly]), input[type=url]:not(.browser-default):focus:not([readonly]), input[type=time]:not(.browser-default):focus:not([readonly]), input[type=date]:not(.browser-default):focus:not([readonly]), input[type=datetime]:not(.browser-default):focus:not([readonly]), input[type=datetime-local]:not(.browser-default):focus:not([readonly]), input[type=tel]:not(.browser-default):focus:not([readonly]), input[type=number]:not(.browser-default):focus:not([readonly]), input[type=search]:not(.browser-default):focus:not([readonly]), textarea.materialize-textarea:focus:not([readonly]) {
  border-bottom: 1px solid #FF5548;
  -webkit-box-shadow: 0 1px 0 0 #FF5548;
  box-shadow: 0 1px 0 0 #FF5548; }

input:not([type]):focus:not([readonly]) + label, input[type=text]:not(.browser-default):focus:not([readonly]) + label, input[type=password]:not(.browser-default):focus:not([readonly]) + label, input[type=email]:not(.browser-default):focus:not([readonly]) + label, input[type=url]:not(.browser-default):focus:not([readonly]) + label, input[type=time]:not(.browser-default):focus:not([readonly]) + label, input[type=date]:not(.browser-default):focus:not([readonly]) + label, input[type=datetime]:not(.browser-default):focus:not([readonly]) + label, input[type=datetime-local]:not(.browser-default):focus:not([readonly]) + label, input[type=tel]:not(.browser-default):focus:not([readonly]) + label, input[type=number]:not(.browser-default):focus:not([readonly]) + label, input[type=search]:not(.browser-default):focus:not([readonly]) + label, textarea.materialize-textarea:focus:not([readonly]) + label {
  color: #FF5548; }

input[readonly]:not(.select-dropdown) {
  color: rgba(0, 0, 0, 0.42);
  border-bottom: 1px dotted rgba(0, 0, 0, 0.42); }

input[type=text][readonly],
textarea[readonly] {
  color: inherit !important;
  border-bottom: 1px solid #9e9e9e !important; }

input[type=checkbox][disabled] + span:before {
  background-color: inherit !important;
  border: 2px solid #5a5a5a !important; }

input[type=checkbox][disabled]:checked + span:not(.lever):before {
  border-right: 2px solid #FF5548 !important;
  border-bottom: 2px solid #FF5548 !important;
  border-left: none !important;
  border-top: none !important; }

label {
  color: #FF5548; }

label.required:after {
  content: ' *';
  color: red;
  margin-left: 2px; }

[type="radio"] + label {
  margin-right: 5px; }
  [type="radio"] + label:after {
    content: ''; }

.btn {
  background-color: #FF5548; }
  .btn:hover {
    background-color: #ff9c95; }
  .btn:focus {
    background-color: #FF5548; }
  .btn.alert {
    background-color: #ff8a80; }
    .btn.alert:hover {
      background-color: #ff5252; }
    .btn.alert:hover {
      background-color: #ff8a80; }

button.btn i {
  vertical-align: text-bottom;
  line-height: initial; }

#image-manager #image-manager-header {
  margin-bottom: 10px; }

#image-manager .image-manager-content {
  min-height: 300px; }
  #image-manager .image-manager-content p {
    width: 100%; }
  #image-manager .image-manager-content #image-manager-form {
    width: 100%;
    border-style: dashed;
    border-radius: 5px; }

#image-manager #edit_image .image-thumbnail-container {
  margin: 10px;
  display: inline-block; }
  #image-manager #edit_image .image-thumbnail-container .image-thumbnail {
    width: 150px;
    height: 150px;
    border: 5px solid #ffffff;
    cursor: pointer; }
    #image-manager #edit_image .image-thumbnail-container .image-thumbnail.selected {
      border-color: #FF5548; }

.select-wrapper .dropdown-content li span {
  color: #FF5548; }

.select-wrapper .dropdown-content li.selected {
  background-color: #FF5548; }
  .select-wrapper .dropdown-content li.selected span {
    color: #ffffff; }

.tabs .tab a {
  color: #ff9c95; }
  .tabs .tab a.active, .tabs .tab a:hover {
    color: #FF5548; }

.tabs .indicator {
  background-color: #FF5548; }

[type="radio"]:checked + label:after,
[type="radio"].with-gap:checked + label:before,
[type="radio"].with-gap:checked + label:after {
  border: 2px solid #FF5548; }

[type="radio"]:checked + label:after,
[type="radio"].with-gap:checked + label:after {
  background-color: #FF5548;
  z-index: 0; }

.fancybox-skin {
  background-color: #eeeded; }

body {
  font-family: "Roboto", sans-serif;
  background-color: #eeeded; }
  body a {
    color: #FF5548; }
  @media (max-width: 992px) {
    body {
      padding-top: 50px; }
      body.menu-open {
        overflow: hidden; }
        body.menu-open:after {
          content: "";
          position: fixed;
          top: 50px;
          left: 0;
          width: 100%;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.8);
          z-index: 1000; } }

nav.top-nav {
  background-color: #FF5548;
  height: 50px;
  line-height: 50px;
  font-size: 14px;
  position: relative;
  z-index: 999; }
  @media (max-width: 992px) {
    nav.top-nav {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 1200; } }
  nav.top-nav .brand-logo {
    display: block;
    float: left;
    text-transform: uppercase;
    font-size: 14px;
    width: 200px;
    padding: 0 20px; }
  nav.top-nav li.dropdown-container ul li a {
    color: #333;
    font-size: 13px; }
  nav.top-nav a {
    color: #fafafa;
    font-weight: normal; }
    nav.top-nav a i {
      height: inherit;
      line-height: inherit;
      font-size: 14px; }
    nav.top-nav a:hover {
      color: #ffffff;
      background-color: inherit; }
  nav.top-nav .sidenav-trigger {
    display: block;
    height: 50px;
    line-height: 50px;
    margin: 0; }
    @media (max-width: 992px) {
      nav.top-nav .sidenav-trigger i.open {
        display: none; }
        .menu-open nav.top-nav .sidenav-trigger i.open {
          display: block; }
      nav.top-nav .sidenav-trigger i.close {
        display: block; }
        .menu-open nav.top-nav .sidenav-trigger i.close {
          display: none; } }

.sidenav li > a {
  display: flex;
  align-items: center;
  min-height: 48px;
  line-height: 1.2; }

.page-content {
  float: left;
  width: 100%; }
  @media (max-width: 992px) {
    .menu-open .page-content {
      overflow: hidden; } }

@media (max-width: 992px) {
  #slide-out {
    margin: 0; }
    #slide-out.pinned, #slide-out.pin-top {
      position: relative !important;
      height: auto; } }

.sidebar-main {
  float: left;
  width: 200px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease; }
  @media (max-width: 992px) {
    .sidebar-main {
      float: none;
      position: fixed;
      top: 50px;
      left: 0;
      bottom: 0;
      overflow: auto;
      transform: translateX(-100%);
      z-index: 1100;
      background-color: #FFF; }
      .menu-open .sidebar-main {
        transform: translateX(0); } }
  .sidebar-main a {
    font-weight: normal; }
  .sidebar-main .side-nav {
    position: relative;
    width: 200px;
    height: 100vh;
    z-index: 997;
    margin-top: 0;
    background-color: white; }
    .sidebar-main .side-nav .userView {
      position: relative;
      overflow: hidden;
      padding: 32px 32px 0; }
      .sidebar-main .side-nav .userView .background {
        position: absolute;
        z-index: -1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0; }
      .sidebar-main .side-nav .userView .name {
        margin: 16px 0;
        font-weight: 500; }
      .sidebar-main .side-nav .userView .sidebar-user-material-menu {
        padding: 0 30px;
        margin: 20px -32px 0;
        background-color: rgba(0, 0, 0, 0.15); }
        .sidebar-main .side-nav .userView .sidebar-user-material-menu a {
          color: #fff;
          text-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
          line-height: 20px;
          padding: 10px 0;
          width: 100%; }
          .sidebar-main .side-nav .userView .sidebar-user-material-menu a i {
            float: right; }
    .sidebar-main .side-nav #main-menu {
      margin: 0;
      box-shadow: none;
      border: none; }
      .sidebar-main .side-nav #main-menu > li > a {
        padding: 0 16px;
        height: 48px;
        line-height: 48px;
        display: flex;
        border-bottom: none;
        font-weight: 500;
        color: #212121; }
        .sidebar-main .side-nav #main-menu > li > a:hover {
          background-color: #f7f7f7; }
        .sidebar-main .side-nav #main-menu > li > a .material-icons {
          color: #757575;
          height: 48px;
          line-height: 48px;
          margin-right: 1rem;
          width: 30px;
          text-align: center; }
        .sidebar-main .side-nav #main-menu > li > a.collapsible-header {
          position: relative; }
          .sidebar-main .side-nav #main-menu > li > a.collapsible-header:after {
            content: "\e315";
            font-family: 'Material Icons';
            font-size: 16px;
            display: block;
            position: absolute;
            top: 50%;
            margin-top: -8px;
            right: 20px;
            line-height: 1;
            -webkit-transform: rotate(0);
            -ms-transform: rotate(0);
            -o-transform: rotate(0);
            transform: rotate(0);
            -webkit-transition: -webkit-transform .2s ease-in-out;
            -o-transition: -webkit-transform .2s ease-in-out;
            transition: -webkit-transform .2s ease-in-out; }
        .sidebar-main .side-nav #main-menu > li > a span.badge {
          position: absolute;
          min-width: inherit;
          border-radius: 10px;
          width: 20px;
          height: 20px;
          display: block;
          top: 30%;
          right: 40px; }
      .sidebar-main .side-nav #main-menu > li.active > a.collapsible-header:after {
        -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg); }
      .sidebar-main .side-nav #main-menu > li .collapsible-body {
        padding: 0; }
        .sidebar-main .side-nav #main-menu > li .collapsible-body ul li {
          height: 40px; }
          .sidebar-main .side-nav #main-menu > li .collapsible-body ul li.active {
            background-color: #FF5548; }
            .sidebar-main .side-nav #main-menu > li .collapsible-body ul li.active a {
              color: white; }
              .sidebar-main .side-nav #main-menu > li .collapsible-body ul li.active a:hover {
                background-color: initial; }
          .sidebar-main .side-nav #main-menu > li .collapsible-body ul li a {
            display: flex;
            padding: 0 23.5px 0 31px;
            color: #212121; }
            .sidebar-main .side-nav #main-menu > li .collapsible-body ul li a:hover {
              background-color: #f7f7f7; }

.sidebar-fav #slide-out-fav {
  width: 400px;
  max-width: calc(100% - 50px); }
  @media (max-width: 992px) {
    .sidebar-fav #slide-out-fav {
      top: 50px;
      bottom: 0; } }
  .sidebar-fav #slide-out-fav li #fav-close {
    padding-right: 25px;
    padding-top: 10px; }
    .sidebar-fav #slide-out-fav li #fav-close i {
      cursor: pointer; }
  .sidebar-fav #slide-out-fav li .divider {
    margin: 0; }
  .sidebar-fav #slide-out-fav li.fav-header {
    background-color: #FF5548; }
    .sidebar-fav #slide-out-fav li.fav-header a, .sidebar-fav #slide-out-fav li.fav-header i {
      color: #ffffff; }
  .sidebar-fav #slide-out-fav li ul li a, .sidebar-fav #slide-out-fav li ul li i {
    font-weight: normal;
    line-height: 17px;
    height: auto;
    padding-top: 5px;
    padding-bottom: 5px;
    min-height: 35px;
    display: flex;
    align-items: center; }
  .sidebar-fav #slide-out-fav li ul li i.material-icons {
    margin-right: 12px; }

.minified-sb .sidebar-main {
  width: 56px;
  overflow: hidden;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease; }

.minified-sb .content-wrapper {
  padding-left: 56px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease; }

.content-wrapper {
  padding-left: 200px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease; }
  @media (max-width: 992px) {
    .content-wrapper {
      padding-left: 0; } }
  .content-wrapper .page-header {
    margin-bottom: 30px;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }
    .content-wrapper .page-header .page-header-content {
      padding: 0 20px; }
      .content-wrapper .page-header .page-header-content .page-title {
        padding: 0;
        height: 100px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between; }
        .content-wrapper .page-header .page-header-content .page-title h4 {
          display: inline-block;
          margin: 0;
          letter-spacing: -.015em;
          font-size: 19px;
          font-family: inherit;
          font-weight: 400;
          line-height: 1.5384616;
          color: inherit; }
          .content-wrapper .page-header .page-header-content .page-title h4 span {
            font-weight: 500; }
        .content-wrapper .page-header .page-header-content .page-title .heading-elements {
          display: inline-block;
          float: right; }
          .content-wrapper .page-header .page-header-content .page-title .heading-elements .company-select {
            float: right; }
          .content-wrapper .page-header .page-header-content .page-title .heading-elements a {
            display: block;
            text-align: center;
            float: right;
            margin: 0 10px; }
            .content-wrapper .page-header .page-header-content .page-title .heading-elements a i {
              color: #FF5548; }
            .content-wrapper .page-header .page-header-content .page-title .heading-elements a span {
              color: #333;
              font-size: 13px; }
            .content-wrapper .page-header .page-header-content .page-title .heading-elements a:hover i, .content-wrapper .page-header .page-header-content .page-title .heading-elements a:hover span {
              color: #ff9c95; }
          .content-wrapper .page-header .page-header-content .page-title .heading-elements input {
            margin-bottom: 0; }
          .content-wrapper .page-header .page-header-content .page-title .heading-elements .dropdown-content li.selected {
            background-color: #FF5548; }
        @media (max-width: 992px) {
          .content-wrapper .page-header .page-header-content .page-title {
            flex-direction: column;
            height: auto; }
            .content-wrapper .page-header .page-header-content .page-title h4,
            .content-wrapper .page-header .page-header-content .page-title .heading-elements {
              width: 100%;
              padding: 5px 0; } }
    .content-wrapper .page-header .breadcrumb-line nav {
      background-color: #fcfcfc;
      border-top: 1px solid #ddd;
      box-shadow: none;
      height: 40px;
      line-height: 40px; }
      .content-wrapper .page-header .breadcrumb-line nav .nav-wrapper a {
        color: #333;
        font-size: 13px; }
        .content-wrapper .page-header .breadcrumb-line nav .nav-wrapper a i {
          display: inline-block;
          font-size: 16px;
          margin-right: 8px;
          height: 40px;
          line-height: 40px; }
        .content-wrapper .page-header .breadcrumb-line nav .nav-wrapper a.breadcrumb:before {
          content: '/';
          color: #333;
          font-size: 13px; }
        .content-wrapper .page-header .breadcrumb-line nav .nav-wrapper a:last-child {
          color: #999; }

.flashbag-messages {
  margin-right: 33.3333333333%; }

.card {
  font-size: 13px; }
  @media (max-width: 992px) {
    .card .datatable-table,
    .card .table-overflow {
      overflow: auto;
      width: 100%; } }
  @media (max-width: 992px) {
    .card .table-overflow-last-times table,
    .card .table-overflow-estimate-history-send table,
    .card .table-overflow-user-task-time table {
      width: clamp(1px, 175%, 100%); } }
  .card .card-title {
    font-size: 17px; }
  @media (max-width: 992px) {
    .card .card-content-charts {
      padding: 0; } }
  .card #estimate-chart,
  .card #estimate-chart-total,
  .card #estimate-chart-regie,
  .card #global-chart,
  .card #rentability-chart,
  .card #rentability-money-chart {
    min-width: 310px;
    max-width: 100%;
    height: 400px;
    margin: 0 auto; }
    @media (max-width: 992px) {
      .card #estimate-chart,
      .card #estimate-chart-total,
      .card #estimate-chart-regie,
      .card #global-chart,
      .card #rentability-chart,
      .card #rentability-money-chart {
        width: 100%;
        min-width: initial; } }
  .card #global-chart {
    height: 650px; }
  .card #rentability-chart,
  .card #rentability-money-chart {
    height: 600px; }
  .card .card-action .row {
    margin-bottom: 0; }
  .card .datepicker {
    min-width: 85px; }
  .card .card-favorite {
    position: absolute;
    top: 24px;
    right: 24px;
    cursor: pointer; }
  @media (max-width: 992px) {
    .card .card-content .btn.mt-10 {
      margin-top: 5px; } }

.waitable .preloader {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.87); }

.waitable.waiting .preloader {
  display: block; }

.chart-container {
  position: relative;
  padding: 0; }
  .chart-container .chart-nav {
    position: absolute;
    top: 0;
    bottom: 0;
    color: #777777;
    z-index: 999; }
    .chart-container .chart-nav i {
      cursor: pointer; }
    .chart-container .chart-nav.disabled {
      color: #E0E0E3;
      cursor: default; }
    .chart-container .chart-nav.right {
      right: 10px; }
    .chart-container .chart-nav.left {
      left: 10px; }

.card .collapsible.no-margin {
  box-shadow: none;
  border-right: none;
  border-left: none; }
  .card .collapsible.no-margin .dataTables_wrapper {
    margin: 0; }
  .card .collapsible.no-margin .contact-card i {
    vertical-align: text-bottom; }

.card .dataTables_wrapper {
  border-top: 1px solid #ddd; }
  .card .dataTables_wrapper .datatable-header {
    float: left;
    width: 100%;
    border-bottom: 1px solid #ddd; }
    .card .dataTables_wrapper .datatable-header .dataTables_length {
      float: right;
      margin-right: 20px; }
      .card .dataTables_wrapper .datatable-header .dataTables_length label {
        display: inline-block; }
        .card .dataTables_wrapper .datatable-header .dataTables_length label > span {
          float: left;
          line-height: 38px;
          margin-right: 10px;
          color: #333; }
        .card .dataTables_wrapper .datatable-header .dataTables_length label .select-wrapper {
          width: 75px;
          float: left;
          color: #333; }
          .card .dataTables_wrapper .datatable-header .dataTables_length label .select-wrapper span {
            top: 14px;
            height: auto; }
          .card .dataTables_wrapper .datatable-header .dataTables_length label .select-wrapper input.select-dropdown {
            height: 38px;
            font-size: 13px; }
          .card .dataTables_wrapper .datatable-header .dataTables_length label .select-wrapper .dropdown-content {
            top: 39px !important; }
            .card .dataTables_wrapper .datatable-header .dataTables_length label .select-wrapper .dropdown-content li.selected {
              background-color: #FF5548; }
  .card .dataTables_wrapper .datatable-table table {
    border-spacing: 0;
    border-collapse: collapse; }
    .card .dataTables_wrapper .datatable-table table tr {
      padding: 0 20px; }
      .card .dataTables_wrapper .datatable-table table tr td, .card .dataTables_wrapper .datatable-table table tr th {
        font-size: 13px; }
      .card .dataTables_wrapper .datatable-table table tr td {
        padding-left: 10px;
        padding-right: 10px; }
        .card .dataTables_wrapper .datatable-table table tr td span.badge {
          position: relative;
          font-weight: bold;
          cursor: pointer; }
          .card .dataTables_wrapper .datatable-table table tr td span.badge.taxonomy {
            margin: 5px 0; }
            .card .dataTables_wrapper .datatable-table table tr td span.badge.taxonomy.accept:after {
              content: ''; }
        .card .dataTables_wrapper .datatable-table table tr td .send_email_action {
          color: #FF5548;
          cursor: pointer; }
        .card .dataTables_wrapper .datatable-table table tr td a {
          color: #FF5548; }
        .card .dataTables_wrapper .datatable-table table tr td .archive_select {
          width: 16px;
          height: 16px;
          border: 1px solid #FF5548;
          cursor: default; }
          .card .dataTables_wrapper .datatable-table table tr td .archive_select input {
            border: 0;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 1px;
            pointer-events: initial; }
          .card .dataTables_wrapper .datatable-table table tr td .archive_select input:checked + i {
            opacity: 1; }
          .card .dataTables_wrapper .datatable-table table tr td .archive_select i {
            font-size: 14px;
            color: #FF5548;
            opacity: 0;
            transition: opacity 0.3s; }
        .card .dataTables_wrapper .datatable-table table tr td .billing-send i {
          font-size: 14px; }
    .card .dataTables_wrapper .datatable-table table thead tr th {
      border-bottom: 1px solid #bbb; }
      .card .dataTables_wrapper .datatable-table table thead tr th input {
        box-sizing: border-box;
        border: 1px solid #9e9e9e;
        border-radius: 5px;
        height: 30px;
        margin: 0;
        padding: 0 10px;
        background-color: #ffffff;
        font-size: 13px;
        font-weight: normal; }
        .card .dataTables_wrapper .datatable-table table thead tr th input:focus, .card .dataTables_wrapper .datatable-table table thead tr th input.filtered {
          border: 1px solid #FF5548;
          box-shadow: 0 1px 0 0 #26a69a; }
      .card .dataTables_wrapper .datatable-table table thead tr th .actions {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start; }
      .card .dataTables_wrapper .datatable-table table thead tr th .reset-filters {
        color: #FF5548;
        cursor: pointer; }
      .card .dataTables_wrapper .datatable-table table thead tr th .btn-archive,
      .card .dataTables_wrapper .datatable-table table thead tr th .btn-email {
        background-color: transparent;
        border: none;
        color: #FF5548;
        appearance: none;
        padding: 0; }
      .card .dataTables_wrapper .datatable-table table thead tr th .archive_all {
        width: 16px;
        height: 16px;
        border: 1px solid #FF5548;
        cursor: default; }
        .card .dataTables_wrapper .datatable-table table thead tr th .archive_all input {
          border: 0;
          clip: rect(0 0 0 0);
          height: 1px;
          margin: -1px;
          overflow: hidden;
          padding: 0;
          position: absolute;
          top: 0;
          left: 0;
          width: 1px;
          pointer-events: initial; }
        .card .dataTables_wrapper .datatable-table table thead tr th .archive_all input:checked + i {
          opacity: 1; }
        .card .dataTables_wrapper .datatable-table table thead tr th .archive_all i {
          font-size: 14px;
          color: #FF5548;
          opacity: 0;
          transition: opacity 0.3s; }
      .card .dataTables_wrapper .datatable-table table thead tr th .btn-email {
        cursor: pointer; }
  .card .dataTables_wrapper .datatable-footer {
    float: left;
    width: 100%;
    border-top: 1px solid #ddd;
    padding: 20px; }
    @media (max-width: 992px) {
      .card .dataTables_wrapper .datatable-footer {
        padding: 20px 0; } }
    .card .dataTables_wrapper .datatable-footer .dataTables_paginate a {
      color: #333; }
      .card .dataTables_wrapper .datatable-footer .dataTables_paginate a.disabled {
        color: #bbb; }
    .card .dataTables_wrapper .datatable-footer .dataTables_paginate span a {
      font-size: 1.2rem;
      padding: 0 10px;
      line-height: 30px;
      border-radius: 5px; }
      .card .dataTables_wrapper .datatable-footer .dataTables_paginate span a.current {
        background-color: #FF5548;
        color: white; }

.select2-group-field .autocomplete_add_block .autocomplete_add {
  margin-top: 20px; }

body.project .notettext p {
  padding: inherit; }

#recap-compta table tbody tr td.choosable {
  cursor: pointer; }

#recap-compta #popup-days {
  display: none; }

#pdf-estimate {
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  background-color: #ffffff;
  border-top: 1px solid #e0e0e0;
  z-index: 5; }
  #pdf-estimate .row {
    margin: 10px 0; }
    #pdf-estimate .row a i {
      vertical-align: bottom;
      margin-right: 5px; }

#pdf-estimate-time {
  background-color: #ffffff;
  border-top: 1px solid #e0e0e0; }
  #pdf-estimate-time .row {
    margin: 10px 0; }
    #pdf-estimate-time .row a i {
      vertical-align: bottom;
      margin-right: 5px; }

form[name="prevision_list"] input[type="text"], form[name="company_list"] input[type="text"], form[name="billing_list"] input[type="text"] {
  height: inherit; }

#prevision-list_wrapper {
  margin: -20px;
  border-top: none; }

#prevision-list .even {
  background-color: #eeeeee; }

#prevision-list td {
  vertical-align: middle; }

#prevision-list .prevision-invoice {
  color: green; }

#prevision-list .prevision-balance {
  color: red; }

#prevision-list .fancy_view2:not([data-total="0.00"]), #prevision-list .fancy_view3:not([data-total="0.00"]) {
  cursor: pointer; }

[type="radio"]:checked + span:after, [type="radio"].with-gap:checked + span:before, [type="radio"].with-gap:checked + span:after {
  border: 2px solid #FF5548;
  background-color: #FF5548; }

[type="checkbox"]:checked + span:not(.lever):before {
  border-right: 2px solid #FF5548;
  border-bottom: 2px solid #FF5548; }

form[name="billing_list"] .s2 .select-wrapper {
  min-width: 135px; }

.input-field.field-autocomplete {
  margin: 0; }

#nav-month a {
  padding: 0; }

@media (max-width: 992px) {
  .fancybox-opened {
    z-index: 10000; } }

@media (max-width: 992px) {
  .breadcrumb-line {
    white-space: nowrap;
    overflow: auto; } }

span.red-flag i {
  color: #FF0000; }

span.month-flag i {
  color: #FF5548; }

span.annual-flag i {
  color: #26a69a; }

.estimate-list .dataTables_wrapper .dataTables_processing {
  position: fixed;
  background: none; }

:root {
  --time-spent-percentage: 0%;
  --white: #FFFFFF;
  --black: #000000;
  --draglist-height: 0;
  --blue-dark: #2F3D44;
  --grey-lighten: #FBFBFB;
  --overtime: #F76363;
  --zone-color: transparent;
  --card-percentage: 0;
  --green: #92BE7B;
  --grey-light: #E7E7E7;
  --add-button: #1D201C; }

.div-times {
  display: flex;
  justify-content: left;
  align-items: center; }
  .div-times i {
    color: #FF5548;
    margin-left: 15px; }

.time-spent-percentage {
  text-align: center;
  width: 200px;
  height: 20px;
  border-radius: 5px;
  border: 1px solid #FF5548;
  position: relative; }
  .time-spent-percentage:before {
    content: '';
    background-color: #FF5548;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: var(--time-spent-percentage);
    max-width: 100%; }
  .time-spent-percentage span {
    position: relative; }

#recap-legend .recap-item {
  padding: 15px 5px;
  border-radius: 2px; }
  @media (max-width: 992px) {
    #recap-legend .recap-item {
      margin: 0.75rem 0; } }

#wiki_filter .btn.mt-10,
#pdf-estimate .btn.mt-10 {
  margin-top: 5px; }

.archive-list .datagrid .first-column {
  width: 50%; }

.archive-list .datagrid .other-column {
  width: 20%; }

.archive-list .datagrid .last-column {
  width: 5%; }

.drag-list .drag-item {
  margin: 15px; }

.drag-column {
  background: none !important; }
  .drag-column .drag-column-header {
    display: block;
    background-color: var(--white);
    padding: 10px 20px;
    border-radius: 10px;
    margin-bottom: 15px;
    border: none; }
    .drag-column .drag-column-header h2 {
      font-size: 16px;
      font-weight: 600;
      color: var(--blue-dark);
      text-align: left;
      text-transform: none;
      line-height: 1.1; }
  .drag-column .drag-inner-list {
    background-color: var(--white) !important;
    border-radius: 15px; }

.drag-item {
  border-radius: 15px;
  box-shadow: none;
  list-style: none; }
  .drag-item a.card-item {
    position: relative;
    background-color: #F5F6F9;
    border-radius: 15px;
    padding: 15px;
    overflow: hidden; }
    .drag-item a.card-item:hover {
      background-color: #F5F6F9; }
    .drag-item a.card-item.nocontract:before, .drag-item a.card-item.nocontract:after {
      display: none; }
  .drag-item:hover .card--item, .drag-item.is-moving .card--item {
    background-color: #FF5548; }
  .drag-item .card--item {
    position: relative;
    background-color: var(--grey-light);
    border-radius: 15px;
    padding: 5px 15px;
    overflow: hidden;
    width: 100%;
    transition: all 0.3s; }
  .drag-item .card--flag {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 9px;
    font-weight: 700;
    color: var(--white);
    padding: 10px 20px;
    background-color: var(--green);
    border-radius: 0 15px 0 15px; }
  .drag-item .card--elapsed-time {
    margin: 5px 0; }
    .drag-item .card--elapsed-time--time {
      font-size: 15px;
      font-weight: 700;
      color: var(--black);
      text-align: center;
      margin-bottom: 5px; }
    .drag-item .card--elapsed-time--progress {
      display: flex;
      align-items: center; }
      .drag-item .card--elapsed-time--progress--bar {
        position: relative;
        flex: 0 1 100%;
        height: 10px;
        border-radius: 20px;
        background-color: #CCCCCC; }
        .drag-item .card--elapsed-time--progress--bar span {
          position: absolute;
          top: 0;
          left: 0;
          width: var(--card-percentage);
          height: 100%;
          border-radius: 20px;
          background-color: var(--zone-color); }
      .drag-item .card--elapsed-time--progress--pc {
        flex: 0 0 auto;
        padding-left: 10px;
        font-size: 12px;
        font-weight: 300;
        text-align: right;
        color: var(--black); }
    .drag-item .card--elapsed-time.overtime .card--elapsed-time--time,
    .drag-item .card--elapsed-time.overtime .card--elapsed-time--progress--pc {
      color: var(--overtime);
      font-weight: 700; }
  .drag-item .card-name {
    font-size: 14px;
    font-weight: 500;
    color: var(--black);
    margin: 0; }
    .drag-item .card-name span {
      font-size: 13px; }
  .drag-item .card--footer {
    margin-top: 10px; }
    .drag-item .card--footer .date {
      font-size: 11px;
      color: var(--blue-dark); }
  .drag-item.open .tasks-list-truncate {
    display: none; }
  .drag-item.open .tasks-list-full {
    display: block; }
  .drag-item .tasks-list {
    padding: 0;
    margin: 0;
    font-size: 12px;
    color: var(--black);
    background-color: transparent;
    margin-top: 5px; }
    .drag-item .tasks-list-truncate {
      transition: all 0.3s;
      max-height: 55px;
      overflow: hidden; }
    .drag-item .tasks-list-full {
      display: none;
      transition: all 0.3s; }
    .drag-item .tasks-list-toggle {
      font-size: 11px;
      color: var(--black);
      margin: 0;
      text-decoration: underline; }
    .drag-item .tasks-list ul li {
      margin: 0; }
  .drag-item a.card-item:hover .tasks-list span {
    white-space: normal; }

@media (min-width: 376px) {
  .planning {
    overflow-x: auto; } }

.planning ul {
  list-style-type: none;
  margin: 0;
  padding: 0; }

.planning--search {
  padding: 0 10px;
  border-radius: 50px;
  background-color: var(--grey-light); }
  @media (max-width: 992px) {
    .planning--search {
      margin: 0 0 10px 0; } }
  .planning--search input[type="search"] {
    color: var(--add-button);
    border: none;
    margin: 0; }
    .planning--search input[type="search"]::placeholder {
      color: var(--add-button); }
    .planning--search input[type="search"]:focus:not([readonly]) {
      border: none;
      box-shadow: none; }

.drag-list {
  height: calc(100vh - var(--draglist-height)); }

.drag-column .drag-inner-list {
  overflow-y: auto; }

.drag-container {
  margin: 0; }

.drag-list {
  display: flex;
  height: calc(100dvh - var(--draglist-height));
  overflow-y: hidden; }
  .planning .drag-list {
    padding-bottom: 20px; }
  .drag-list .drag-item {
    margin: 15px 0; }
    .drag-list .drag-item:first-child {
      margin-top: 0; }
    .drag-list .drag-item:last-child {
      margin-bottom: 0; }

.drag-header-move {
  cursor: pointer; }

.drag-column {
  flex: 0 0 16%;
  background: none !important;
  margin: 0 5px;
  display: flex;
  flex-direction: column;
  position: relative;
  background: rgba(0, 0, 0, 0.1); }
  @media (max-width: 690px) {
    .drag-column {
      margin-bottom: 30px; } }
  @media (max-width: 375px) {
    .drag-column {
      flex-basis: 80%; } }
  .drag-column h2 {
    margin: 0; }
  .drag-column-done {
    opacity: .6; }
  .drag-column-header {
    display: block;
    background-color: var(--white);
    padding: 10px 20px;
    border-radius: 10px;
    margin-bottom: 15px;
    border: none;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-right: 1px transparent;
    font-size: 13px;
    padding-top: 5px;
    position: sticky;
    top: 0;
    z-index: 10; }
    .drag-column-header h2 {
      font-size: 16px;
      font-weight: 600;
      color: var(--blue-dark);
      text-align: left;
      text-transform: none;
      line-height: 1.1;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      margin: 0; }
  .drag-column .drag-inner-list {
    background-color: var(--white);
    border: 15px solid var(--white);
    border-right-width: 5px;
    border-radius: 15px;
    overflow-y: auto;
    padding-right: 10px; }

.drag-inner--list {
  min-height: 50px;
  flex: 1; }

.drag-item {
  border-radius: 15px;
  box-shadow: none;
  list-style: none; }
  .planning .drag-item .card-item {
    cursor: pointer; }
  .drag-item a {
    display: block;
    color: inherit;
    position: relative;
    z-index: 1;
    transition: .2s all; }
    .drag-item a.card-item:hover {
      cursor: pointer; }
    .drag-item a.merge {
      background: rgba(255, 255, 255, 0.5);
      font-size: 0;
      text-align: center; }
      .drag-item a.merge:hover {
        background: rgba(255, 255, 255, 0.8); }
      .drag-item a.merge svg {
        transform: rotate(-90deg); }
  .drag-item.active a:after {
    position: absolute;
    z-index: 0;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(128, 128, 128, 0.3);
    animation: blinker 1s linear infinite; }
  .drag-item.is-moving {
    background: white; }
    .drag-item.is-moving a {
      cursor: move;
      /* fallback if grab cursor is unsupported */
      cursor: grabbing; }

.drag-options {
  position: absolute;
  top: 44px;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 10px;
  transform: translateX(100%);
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1); }
  .drag-options.active {
    transform: translateX(0);
    opacity: 1; }
  .drag-options-label {
    display: block;
    margin: 0 0 5px 0; }
    .drag-options-label input {
      opacity: 0.6; }
    .drag-options-label span {
      display: inline-block;
      font-size: 0.9rem;
      font-weight: 400;
      margin-left: 5px; }

.card-item .color-stripe {
  min-height: 4px;
  position: relative;
  z-index: 9;
  margin-bottom: -1px; }

/* Dragula CSS  */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important; }

.gu-hide {
  display: none !important; }

.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important; }

.gu-transit {
  opacity: 0.4; }
